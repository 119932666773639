import {
    deleteDataById,
    getData, getExcel,
    getPaginatedData,
    getSingleData,
    insertData,
    patchData,
} from './commonService';

export default class EmployeeService {


    #defaultUrl = '/api/Employee';
    #defaultResourceName = 'Çalışan';

    async getAllEmployees() {
        return await getPaginatedData(this.#defaultUrl + '/query', this.#defaultResourceName);
    }

    async getAllEmployeesExcel() {
        return await getExcel(this.#defaultUrl + "/excel", this.#defaultResourceName);
    }

    async getEmployeeById(projectId) {
        return await getSingleData(this.#defaultUrl + '/query?Id=' + projectId , this.#defaultResourceName);
    }

    async getEmployeeByIdentityNumber(identityNumber) {
        return await getData(this.#defaultUrl + '/query?identityNumber=' + identityNumber, this.#defaultResourceName);
    }

    async checkIdentityNumberIsAlreadyUsed(identityNumber) {
        let response = await getData(this.#defaultUrl + '/query?identityNumber=' + identityNumber, this.#defaultResourceName);
        if (response.isSuccess) {
            if (response.data != null && response.data.data.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    async insertEmployee(dataItem) {
        const createData = {
            'name': dataItem.name,
            'surName': dataItem.surName,
            'identityNumber': dataItem.identityNumber,
            'birthday': dataItem.birthday,
            'gender': dataItem.gender,
            'employeePhoto': dataItem.employeePhoto,
            'managerUserId': dataItem.managerUserId,
        };


        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async deleteById(employeeId) {
        return await deleteDataById(this.#defaultUrl, employeeId, this.#defaultResourceName);
    }

    async updateEmployeePhoto(employeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'employeePhoto', 'value': dataItem.employeePhoto },
        ];

        return await this.patchEmployee(employeeId, patchDocument);
    }

    async updateEmployee(employeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'name', 'value': dataItem.name },
            { 'op': 'replace', 'path': 'surName', 'value': dataItem.surName },
            { 'op': 'replace', 'path': 'isActive', 'value': dataItem.isActive },
            { 'op': 'replace', 'path': 'identityNumber', 'value': dataItem.identityNumber },
            { 'op': 'replace', 'path': 'birthday', 'value': dataItem.birthday },
            { 'op': 'replace', 'path': 'gender', 'value': dataItem.gender },
            { 'op': 'replace', 'path': 'isBanned', 'value': dataItem.isBanned },
            { 'op': 'replace', 'path': 'BanReason', 'value': dataItem.banReason },
        ];

        return await this.patchEmployee(employeeId, patchDocument);
    }

    async updateEmployeePersonelInformation(employeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'education', 'value': dataItem.education },
            { 'op': 'replace', 'path': 'lastSchool', 'value': dataItem.lastSchool },
            { 'op': 'replace', 'path': 'militaryStatus', 'value': dataItem.militaryStatus },
            { 'op': 'replace', 'path': 'maritalStatus', 'value': dataItem.maritalStatus },
            { 'op': 'replace', 'path': 'foreignLanguage', 'value': dataItem.foreignLanguage },
            { 'op': 'replace', 'path': 'childCount', 'value': dataItem.childCount },
        ];

        return await this.patchEmployee(employeeId, patchDocument);
    }

    async updateEmployeeContactInformation(employeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'address', 'value': dataItem.address },
            { 'op': 'replace', 'path': 'cityId', 'value': dataItem.cityId },
            { 'op': 'replace', 'path': 'townId', 'value': dataItem.townId },
            { 'op': 'replace', 'path': 'gsm', 'value': dataItem.gsm },
            { 'op': 'replace', 'path': 'email', 'value': dataItem.email },
            { 'op': 'replace', 'path': 'emergencyPersonName', 'value': dataItem.emergencyPersonName },
            { 'op': 'replace', 'path': 'emergencyPersonPhone', 'value': dataItem.emergencyPersonPhone },
            { 'op': 'replace', 'path': 'employeePhoto', 'value': dataItem.employeePhoto },
        ];

        return await this.patchEmployee(employeeId, patchDocument);
    }

    async patchEmployee(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}