import { getData, getExcel, insertData, insertDataReturnEntity, patchData, patchRawData } from './commonService';

export default class ProjectEmployeeService {

    #defaultUrl = '/api/ProjectEmployee';
    #defaultResourceName = 'Çalışan';

    async getActiveEmployeeWithIdentityNumber(employeeId) {
        let response = await getData('/api/ProjectEmployee/query?employeeId=' + employeeId + '&IsStillWork=true', this.#defaultResourceName);
        if (response.isSuccess) {
            return response;
        } else {
            return null;
        }
    }

    async insertEmployeeToProject(dataItem) {
        const createData = {
            'projectId': dataItem.projectId,
            'employeeId': dataItem.employeeId,
            'officialSalary': dataItem.officialSalary,
            'unOfficialSalary': dataItem.unOfficialSalary,
            'insuranceDeposit': dataItem.insuranceDeposit,
            'department': dataItem.department,
            'iban': dataItem.iban,
            'bankName': dataItem.bankName,
            'startDate': dataItem.startDate,
            'isActive': 'true',
            'isRetired': dataItem.isRetired
        };
        return await insertDataReturnEntity('/api/ProjectEmployee', createData, this.#defaultResourceName);
    }

    async getAllActiveProjectEmployees() {
        return await getData('/api/ProjectEmployee/query?ActiveOnly=true', this.#defaultResourceName);
    }

    async getProjectEmployees(projectId) {
        return await getData('/api/ProjectEmployee/query?projectId=' + projectId + '&ActiveOnly=true', this.#defaultResourceName);
    }

    async getProjectEmployeesSalaryChanges(projectId) {
        return await getData('/api/ProjectEmployee/query?projectId=' + projectId + '&IsSalaryChanged=true', this.#defaultResourceName);
    }

    async getProjectEmployeesSalaryChangesForAccounting(projectId) {
        return await getData('/api/ProjectEmployee/query?projectId=' + projectId + '&IsSalaryChanged=true', this.#defaultResourceName);
    }

    async getProjectEmployeesHistory(projectId) {
        return await getData('/api/ProjectEmployee/query?projectId=' + projectId + '&PassiveOnly=true', this.#defaultResourceName);
    }

    async getProjectEmployeeReports(projectId) {
        return await getData('/api/ProjectEmployee/report?projectId=' + projectId + '&IsStillWork=true', this.#defaultResourceName);
    }

    async getProjectEmployeesExcel(projectId) {
        return await getExcel('/api/ProjectEmployee/excel?projectId=' + projectId + '&ActiveOnly=true&PassiveOnly=false', this.#defaultResourceName);
    }

    async getProjectEmployeesHistoryExcel(projectId) {
        return await getExcel('/api/ProjectEmployee/excel?projectId=' + projectId + '&PassiveOnly=true', this.#defaultResourceName);
    }

    async getProjectEmployeeById(projectEmployeeId) {
        return await getData('/api/ProjectEmployee/' + projectEmployeeId, this.#defaultResourceName);
    }

    async updateSGKStatus(projectEmployeeId, status)
    {
        const patchDocument = [
            { 'op': 'replace', 'path': 'isSgkProcessDone', 'value':status }
        ];

        return await patchRawData('/api/ProjectEmployee/' + projectEmployeeId, patchDocument, this.#defaultResourceName);
    }

    async updateProjectEmployee(projectEmployeeId, dataItem)
    {
            const patchDocument = [
                { 'op': 'replace', 'path': 'officialSalary', 'value': dataItem.officialSalary },
                { 'op': 'replace', 'path': 'unOfficialSalary', 'value': dataItem.unOfficialSalary },
                { 'op': 'replace', 'path': 'isSalaryChanged', 'value': 'true' },
            ];

        return await patchRawData('/api/ProjectEmployee/' + projectEmployeeId, patchDocument, this.#defaultResourceName);
    }

    async updateProjectEmployeeBankInformation(projectEmployeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'iban', 'value': dataItem.iban },
            { 'op': 'replace', 'path': 'bankName', 'value': dataItem.bankName },
        ];

        return await patchRawData('/api/ProjectEmployee/' + projectEmployeeId, patchDocument, this.#defaultResourceName);
    }


    async updateProjectEmployeeWorkStatus(projectEmployeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'startDate', 'value': dataItem.startDate },
            { 'op': 'replace', 'path': 'endDate', 'value': dataItem.endDate },
            { 'op': 'replace', 'path': 'isActive', 'value': dataItem.isActive },
            { 'op': 'replace', 'path': 'isRetired', 'value': dataItem.isRetired },
            { 'op': 'replace', 'path': 'passiveReason', 'value': dataItem.passiveReason },
            { 'op': 'replace', 'path': 'department', 'value': dataItem.department },
            { 'op': 'replace', 'path': 'passiveProcessDate', 'value': dataItem.passiveProcessDate },
            { 'op': 'replace', 'path': 'passiveManagerUserId', 'value': dataItem.passiveManagerUserId },

        ];

        return await patchRawData('/api/ProjectEmployee/' + projectEmployeeId, patchDocument, this.#defaultResourceName);
    }

    async updateProjectEmployeeSalaryChange(projectEmployeeId, dataItem) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'officialSalary', 'value': dataItem.officialSalary },
            { 'op': 'replace', 'path': 'unOfficialSalary', 'value': dataItem.unOfficialSalary },
            { 'op': 'replace', 'path': 'isSalaryChanged', 'value': dataItem.isSalaryChanged },
        ];

        return await patchRawData('/api/ProjectEmployee/' + projectEmployeeId, patchDocument, this.#defaultResourceName);
    }

    async updatePassiveStatus(id, newStatus) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'isPassiveApproved', 'value': newStatus },
        ];

        return await patchData('/api/ProjectEmployee', id, patchDocument, this.#defaultResourceName);

    }

    async updateSalaryChange(id, newStatus) {
        const patchDocument = [
            { 'op': 'replace', 'path': 'isSalaryChanged', 'value': newStatus },
        ];

        return await patchData('/api/ProjectEmployee', id, patchDocument, this.#defaultResourceName);
    }

    async insertEmployeeSalaryChange(dataItem) {
        const createData = {
            'projectEmployeeId': dataItem.projectEmployeeId,
            'managerUserId': dataItem.managerUserId,
            'officialSalary': dataItem.officialSalary,
            'unOfficialSalary': dataItem.unOfficialSalary,
            'bankName': dataItem.bankName,
            'iban': dataItem.iban,
            "oldOfficialSalary": dataItem.oldOfficialSalary,
            "oldUnOfficialSalary": dataItem.oldUnOfficialSalary
        };
        return await insertData('/api/ProjectEmployeeSalaryChange/', createData, this.#defaultResourceName);
    }

    async updateEmployeeSalaryChangeLog(id, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "approveDate", "value": dataItem.approveDate},
            {"op": "replace", "path": "approveManagerUserId", "value": dataItem.approveManagerUserId},
            {"op": "replace", "path": "isApproved", "value": dataItem.isApproved},
            { 'op': 'replace', 'path': 'officialSalary', 'value': dataItem.officialSalary },
            { 'op': 'replace', 'path': 'unOfficialSalary', 'value': dataItem.unOfficialSalary },
        ];

        return await patchData('/api/ProjectEmployeeSalaryChange', id, patchDocument, this.#defaultResourceName);
    }

}